@import '~@/sass/_variables.scss';
$last-height-factor:9px;
$last-width-factor:16px;
$layer-height:$last-height-factor * 9;
$layer-resource-width:$last-width-factor * 9;
$grayish-blue:#607D8B;
$visual-layer-color-1:#ff0040;
$audio-layer-color-1:#bf00ff;
$scrollbar-width:8px;

.layers{

  .action-addLayer{
    width:fit-content;
    cursor:pointer;
    box-sizing:border-box;
    padding:5px 8px;
    border-radius:5px;
    margin-bottom:10px;

    &.visual{
      color:$visual-layer-color-1;
      border:1px solid $visual-layer-color-1;
    }
    &.audio{
      color:$audio-layer-color-1;
      border:1px solid $audio-layer-color-1;
    }
  }
  &#visual, &#audio{

    .layer{
      display:flex;
      flex-flow:row;
      margin-bottom:5px;
      box-shadow:0 5px 10px #dedede;
      min-height:$layer-height;
      max-height:( $layer-height * 3 ) + ( $scrollbar-width * 2 ) + ( 2px * 2 ) + 10px;

      .info{
        position:relative;
        display:flex;
        flex-flow:column;
        align-items:center;

        .replacers-info, .layer-info, .appenders-info{
          display:flex;
          flex:1;
          justify-content:center;
          align-items:center;
          width:100%;
          box-sizing:border-box;
        }
        .replacers-info{
          border-bottom:1px dashed #ffeb3b;
        }
        .replacers-info, .appenders-info{
          font-size:.7em;
          font-style:italic;
          color:#ffeb3b;
          height:$layer-height + $scrollbar-width;

          &::before, &::after{
            content:'"';
          }
        }
        .layer-info{
          height:$layer-height;
          display:flex;
          flex-flow:row;

          .shuffle-config{
            overflow:hidden;
            max-width:$layer-height + 20px;
            width:0;
            transition:all .2s;

            .shuffling-period{
              .inputs{
                display:flex;
                flex-flow:row;

                .every{
                  width:40%;
                }
                .metric{
                  width:60%;
                }
              }
            }
          }
          .basic{
            width:$layer-height;
            display:flex;
            height:100%;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            .action-remove, .action-edit, .action-mute{
              position:absolute;
              width:fit-content;

              transition:all .2s;
              cursor:pointer;

              &:hover{
                transform:scale(1.2) rotate(90deg);
              }
            }
            .action-mute{
              top:5px;
              right:50px;
            }
            .action-edit{
              top:8px;
              right:30px;

              i{
                font-size:1em;
              }
            }
            .action-remove{
              top:5px;
              right:5px;
            }
            input{
              width: 100%;
              background-color: transparent;
              padding: unset;
              margin: unset;
              border: none;
              text-align:center;
              margin-bottom:5px;
            }

            .elements-count{

            }
          }
        }

        .appenders-info{
          border-top:1px dashed #ffeb3b;
        }
      }

      .quadrumvirato-wrapper{
        display:flex;
        flex:1;
        flex-flow:column;
        overflow-x:auto;
        flex-wrap:wrap;

        .replacers-list, .appenders-list{
          height:$layer-height + $scrollbar-width + 2px;
        }
        .replacers-list{
          padding-left:$layer-resource-width / 2;
        }
        .parent-list{
          height:$layer-height;
          padding-left:$layer-resource-width / 2;
        }
        .buckets-list{
          position:relative;
          display:flex;
          flex-flow:row;
          height:9px * 4;
          background-color:#efefef;

          &::before{
            content:"BUCKETS AREA";
            color:#bdbdbd;
            position:absolute;
            left:10px;
            top:10px;
          }
          .bucket{
            position:relative;
            z-index:1;
            display:flex;
            flex-flow:row;
            align-items:center;
            padding: 5px;
            border: 1px solid lightgray;
            background-color:#efefef;


            .actions{
              padding:4px;

              .edit, .remove{
                color:white;
                cursor:pointer;
                transition:all .2s;
                text-shadow:0 0 10px black;

                &:hover{
                  transform:scale(1.2);
                }
              }
            }
          }
        }
        .inertElements-list{
          &::before{
            content:"RESOURCES AREA";
            color:#bdbdbd;
            position:absolute;
            left:10px;
            top:10px;
          }
        }
        .replacers-list, .parent-list, .appenders-list, .inertElements-list{
          position:relative;
          display:flex;
          flex-flow:row;
          flex:1;
          min-height:81px;

          .replacer-wrapper{
            display:flex;
            width:$layer-resource-width;
            overflow-x:scroll;
            box-sizing:border-box;
            border:1px dashed lightgray;
            transition:all .2s;
            scrollbar-width: thin;

            &:hover{
              border-color:$visual-layer-color-1;
              cursor:copy;
            }
            .replacer{
              display:flex;
              flex:1;
              flex-flow:row;
              width:fit-content;

            }
          }
          .appender-wrapper-for-arrow{
            position:relative;
            display:flex;
            width:$layer-resource-width;
            box-sizing:border-box;
            border:1px dashed lightgray;
            transition:all .2s;
            scrollbar-width: thin;

            &:hover{
              border-color:$visual-layer-color-1;
              cursor:copy;
            }
            &::before{
              content:'';
              position:absolute;
              top:-10px;
              left:calc(50% - 10px);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 10px 10px 10px;
              border-color: transparent transparent #04ff10 transparent;
            }
            .appender-wrapper{
              display:flex;
              flex:1;
              overflow-x:scroll;

             .appender{
                display:flex;
                flex:1;
                flex-flow:row;
                width:fit-content;
              }
            }
          }
          .resource{
            position:relative;
            display:flex;
            flex-flow:column;
            justify-content:flex-end;
            align-items:flex-end;
            border-right:1px solid lightgray;
            box-sizing:border-box;
            width:$layer-resource-width;
            height:9px * 9;
            //overflow-x:hidden;
            //cursor:n-resize;
            scrollbar-width:0;
            &::-webkit-scrollbar{
              width:0;
            }
            background-position:center!important;
            background-repeat:no-repeat!important;
            background-size:cover!important;

            .locked{
              position: absolute;
              left: calc(50% - .5em);
              top: calc(50% - .5em);
              color: rgba(255,255,255,.5);
              font-size: 2em;
            }
            .actions{
              display:flex;
              flex-flow:row;
              position:absolute;
              top:0;
              right:0;
              padding:4px;

              .edit, .remove{
                color:white;
                cursor:pointer;
                transition:all .2s;
                text-shadow:0 0 10px black;

                &:hover{
                  transform:scale(1.2);
                }
              }
            }
            .bucket-id{
              color:#e8ff00;
              font-size:.7em;
              font-style:italic;
              padding:4px;
              background-color:rgba(0,0,0,.5);
            }
            .resource-title{
              width:100%;
              box-sizing:border-box;
              padding:4px;
              background-color:rgba(0,0,0,.5);
              color:white;
              font-size:.7em;
            }
          }
        }
        .parent-list{

          .resource{
            position:relative;

            &::before{
              content:'';
              position:absolute;
              top:0;
              left:calc(50% - 10px);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 10px 0 10px;
              border-color: #04ff10 transparent transparent transparent;
            }
          }
        }

      }
    }
  }
  &#visual{
    .info .layer-info .basic{
      background-color:$visual-layer-color-1;
    }
  }
  &#audio{
    .info .layer-info .basic{
      background-color:$audio-layer-color-1;
    }
  }
}
