.modal-body{
  .type-selector{
    margin-bottom:20px;


    input[type="radio"]{
      display:none;

      &:checked{

        &+label{
          background-color:#4caf50;
          color:white;
        }
      }
    }

    label{
      cursor:pointer;
      border:1px solid #9E9E9E;
      padding:5px 10px;
      border-radius:5px;
    }
  }
}
