@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import "variables";

@import "~vue-multiselect/dist/vue-multiselect.min";

@import "~@/sass/components/Tooltip";
@import "~@/sass/components/MessageBag";
@import "~@/sass/components/Navigation";
@import "~@/sass/components/Grid";
@import "~@/sass/components/Modal";
@import "~@/sass/components/AvailableResources";
@import "~@/sass/components/ProgrammeBuilder";
@import "~@/sass/components/Edit_ProgrammeResource";
@import "~@/sass/components/Endpoints/Machines/Index";
@import "~@/sass/components/Endpoints/Resources/Index";
@import "~@/sass/components/Endpoints/Resources/Create";
@import "~@/sass/components/Endpoints/Resources/Edit";

body{
  font-family: 'Montserrat', sans-serif;

  & ,p{
    margin:0;
    padding:0;
  }

}

::-webkit-scrollbar-track-piece{
        background-color:#fff;
        -webkit-border-radius:0;
}
::-webkit-scrollbar{
        width:8px;
        height:8px;
}
::-webkit-scrollbar-thumb{
        height:50px;
        background-color:#999;
        -webkit-border-radius:4px;
        outline:2px solid #fff;
        outline-offset:-2px;
        border: 2px solid #fff;
}

.material-icons{
  display: inline-flex;
  vertical-align: middle;
}

.form-group{
  display:flex;
  flex-flow:column;
  margin-bottom:10px;

  label{
    font-style: italic;
    font-size:0.8em;
  }
}

.info-message{
  background-color:#ffecb3;
  font-style:italic;
  font-weight: bold;
  font-size:0.9em;
  color:#FF6F00;
  padding:10px;
  margin:10px;
}

.action-close{
  display:flex;
  align-items:center;
  position:relative;
  width:25px;
  cursor:pointer;


  .material-icons{
    transition:all .2s;
    width:100%;
    overflow:hidden;
  }
  &:hover i{
    color:$red1;
    transform:rotate(90deg);
  }

  div{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
  }
}

.alert{
  color:#f44336;
}
