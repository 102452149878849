$green:#388e3c;
nav{
  display:flex;
  flex-flow:row;
  padding:5px;

  a{
    color:gray;
    text-decoration:none;
    padding:5px 10px;
    border-radius:5px;
    transition:all .2s;

    &:hover{
      transform:scale(1.1);
    }
    &.router-link-active{
      color:$green;
      border:1px solid $green;
      cursor:default;
    }
  }
}
