@import '~@/sass/_variables.scss';


.ok-and-x-buttons{
  position:absolute;
  z-index:2;
  top:20px;
  right:20px;
  width:150px;

  .x-button{
      float:right;
      cursor:pointer;
  }
}

.resource-duration,
.resource_dimensions-width,
.resource_dimensions-height,
.resource_position-on-x-axis,
.resource_position-on-y-axis
{
  position:relative;
  padding: 0;
  margin: 0;
  list-style: none;
  display:flex;
  flex-flow: wrap row;
  height:34px;

  input[type='number']{
    height:34px;
    border:1px solid #d4d4d4;
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
    padding:5px;
  }

  .resource-duration-flex-item, .darc_flex-item{
    width: 34px;
    height: 34px;
    font-size: 1.3em;
    text-align: center;

    input[type='radio']{
      display:none;

      &:checked{

        &+label{
          background-color:green;
          border:none;
          color:white;
        }
      }
    }

    label{
      cursor:pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color:#eaeaea;
      border:1px solid #d4d4d4;
    }
  }
}

.darc_preview{
  position:relative;

  .darc_origin{
    position:relative;
    z-index:2;
    width:inherit;
    height:inherit;
    display: flex;
    flex-direction: column;

    .darc_top-row, .darc_middle-row, .darc_bottom-row{
      display: flex;
      flex:1;
      flex-wrap: nowrap;

      .darc_left, .darc_middle, .darc_right{
        display:flex;
        flex:1;

        input[type="radio"]{
          display:none;

          &:checked{

            &+label{
              border-color:#4caf50c4;
            }
          }
        }
        label{
          text-shadow:0 0 10px white;
          display:flex;
          flex:1;
          align-items:center;
          justify-content:center;
          cursor:pointer;
          margin:0;
        }
      }
    }

    .darc_top-row, .darc_bottom-row{

      .darc_left{

        label{
          border-left:5px solid #c4c4c4c4;
        }
      }
      .darc_right{

        label{
          border-right:5px solid #c4c4c4c4;
        }
      }
    }

    .darc_top-row{

      .darc_left, .darc_right{

        label{
          border-top:5px solid #c4c4c4c4;
        }
      }
    }

    .darc_bottom-row{

      .darc_left, .darc_right{

        label{
          border-bottom:5px solid #c4c4c4c4;
        }
      }
    }
  }

  .darc_preview-object{
    position:absolute;
    //z-index:1;
    border:1px dashed gray;
  }
}
