.resources-selection-menu{
  margin-bottom:20px;
}

.selected-resources{
  .resource-heading-wrapper{
    display:flex;
    flex-flow:row;
    align-items:center;

    .resource-title{
      margin:0;
      font-style: italic;
    }
  }
}
