$error-color:#b71c1c;
$success-color:#2e7d32;

ul.message-bag{
  display:flex;
  flex-flow:column;
  list-style-type:none;
  margin-bottom:10px;
  font-size:.9em;
  position:fixed;
  top:20px;
  right:50px;
  width:25vw;
  z-index:200;

  li.message{
    width:100%;
    box-sizing:border-box;
    border-radius:10px;
    background:rgba(255, 255, 255, .8);
    margin-bottom:5px;
    max-height:100px;
    overflow-y:scroll;

    &.error{
      border:1px dashed $error-color;
      color:$error-color;

      .message-heading{
        border-bottom:1px solid $error-color;

        .action-close{

          i.material-icons{
            color:$error-color;
          }
        }
      }
    }
    &.success{
      border:1px dashed $success-color;
      color:$success-color;

      .message-heading{
        border-bottom:1px solid $success-color;

        .action-close{

          i.material-icons{
            color:$success-color;
          }
        }
      }
    }
    .message-heading{
      display:flex;
      flex-flow:row;
      align-items:center;
      padding:5px;

      .title{
        flex:1;
        font-weight:bold;
      }
      .action-close{
        width:fit-content;
        display:flex;
        justify-content:flex-end;

        i.material-icons{
          cursor:pointer;
          transition:all .2s;

          &:hover{
            transform:scale(1.2) rotate(90deg);
          }
        }
      }
    }
    .description{
      padding:5px 5px 10px;
      font-size:.9em;
      font-style:italic;
    }
  }
}
