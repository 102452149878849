
$filesPerRow:6;
$grayish-blue:#607D8B;
$green:green;
$private-color:#0277bd;
$privacy-transition-color:gray;
$public-color:#d84315;

.top-bar{
  overflow:hidden;
  transition:all .2s;

  .left-section{
    display:flex;
    flex-flow:column;
    flex:1;
    box-sizing:border-box;
    padding-right:10px;

    .about-programme{
      flex:1;

      .input-area{
        display:flex;
        flex-flow:column;

        label{
          color:#455a64;
          font-family:inherit;
          font-size:.8em;
          font-style:italic;
        }
        #programme-title, #programme-description, #programme-parent{
          width:100%;
          border:1px solid lightgray;
          border-radius:5px;
          color:#455a64;
          box-sizing:border-box;
          padding:7px;
          font-family:inherit;
          margin-bottom:10px;
        }
        #programme-title{

        }
        #programme-description{

        }
        #label-of_programme-is-public{
          //width:fit-content;
          background-color:$private-color;
          color:white;
          border-radius:5px;
          box-sizing:border-box;
          padding:5px 12px;
          cursor:pointer;
          transition:all .2s;
          margin-bottom:10px;

          &:after{
            content:"is private";
          }
          &:hover{
            background-color:$privacy-transition-color;

            &:after{
              content:"make public"
            }
          }
          &.checked{
            background-color:$public-color;
            color:white;

            &:after{
              content:"is public";
            }
            &:hover{
              background-color:$privacy-transition-color;

              &:after{
                content:"make private"
              }
            }
          }
        }
        input[type="checkbox"]{
          display:none;
        }
        .apply-preset{
          color:#d84315;
          background-color:transparent;
          width:fit-content;
          border:1px solid #d84315;
          border-radius:5px;
          box-sizing:border-box;
          padding:5px 12px;
          cursor:pointer;
          transition:all .2s;
          margin-bottom:10px;

          &:hover{
            margin-left:10px;
          }
        }
        #submit-programme{
          display:flex;
          align-items:center;
          width:fit-content;
          background-color:transparent;
          border-radius:5px;
          box-sizing:border-box;
          border:1px solid $green;
          color:green;
          padding:5px 12px;
          transition:all .2s;
          cursor:pointer;

          &:hover{
            margin-left:10px;
          }
        }
      }
    }
  }
  .right-section{
    width:85%;
    min-width:( ( 16px * 10 ) * $filesPerRow ) + ( $filesPerRow * 5px ) + 10px * 2 + 4px;
    max-height:250px;
    overflow-y:auto;

    .used-files{
      display:flex;
      width:100%;
      flex-flow:column;
      border:1px dashed lightgray;
      box-sizing:border-box;
      padding:5px 10px;
      margin-right:5px;
      border-radius:20px;

      .tabs{
        display:flex;
        flex-flow:row;

        .tab.section-title{
          border:1px solid lightgray;
          border-radius:10px;
          padding:5px;
          margin-right:5px;
          color:lightgray;
          font-size:1em;
          margin:5px 5px 5px 0;
          cursor:pointer;

          &.active{
              border-color:#8bc34a;
              color:#8bc34a;
          }
        }
      }

      .tabs-content{

        .resources{
          display:flex;
          flex-flow:row;
          flex-wrap:wrap;

          .resource{
            position:relative;
            display:flex;
            flex-flow:column;
            align-items:flex-end;
            justify-content:flex-end;
            width:16px * 10;
            height:9px * 10;
            overflow-x:hidden;
            //cursor:n-resize;
            scrollbar-width:0;
            &::-webkit-scrollbar{
              width:0;
            }
            box-shadow:0 0 10px lightgray;
            border-radius:15px;
            margin: 0 5px 5px 0;
            background-position:center!important;
            background-repeat:no-repeat!important;
            background-size:cover!important;
            cursor:grab;

            &.bucketfull{
              box-sizing:border-box;
              border:1px solid #e8ff00;
              position:relative;
              user-select:none;
              cursor:not-allowed;

              &::before{
                content:'';
                position:absolute;
                width:100%;
                height:100%;
                //background-color:rgba(255,255,255,0.6);
              }

              &.blocked{
                border:1px solid red;
              }
            }

            &.action-add{
              display:flex;
              justify-content:center;
              align-items:center;
              border:1px dashed #8bc34a;
              color:#8bc34a;
              box-shadow:none;
              cursor:pointer;

              &:hover{
                i{
                  transform:scale(1.2);
                }
              }
              i{
                transition:all .2s;
                font-size:2em;
              }
            }
            .action-remove, .action-block, .action-unblock{
              position:absolute;
              top:0;
              right:0;
              padding:4px;
              color:white;
              cursor:pointer;
              transition:all .2s;
              text-shadow: 0 0 10px black;;

              &:hover{
                transform:scale(1.2);
              }
            }
            .bucket-id{
              color:#e8ff00;
              font-size:.7em;
              font-style:italic;
              padding:4px;
              background-color:rgba(0,0,0,.5);
            }
            .resource-title{
              width:100%;
              box-sizing:border-box;
              padding:4px;
              background-color:rgba(0,0,0,.5);
              color:white;
              font-size:.8em;
            }
          }
        }
        .buckets{
          display:flex;
          flex-flow:row;
          flex-wrap:wrap;

          .bucket{
            position:relative;
            display:flex;
            align-items:center;
            justify-content:center;
            width:16px * 10;
            height:9px * 4;
            overflow-x:hidden;
            //cursor:n-resize;
            scrollbar-width:0;
            &::-webkit-scrollbar{
              width:0;
            }
            box-shadow:0 0 10px lightgray;
            border-radius:15px;
            margin: 0 5px 5px 0;
            background-position:center!important;
            background-repeat:no-repeat!important;
            background-size:cover!important;
            cursor:grab;

            &.action-add{
              display:flex;
              justify-content:center;
              align-items:center;
              border:1px dashed #8bc34a;
              color:#8bc34a;
              box-shadow:none;
              cursor:pointer;

              &:hover{
                i{
                  transform:scale(1.2);
                }
              }
              i{
                transition:all .2s;
                font-size:2em;
              }
            }
            .action-remove{
              position:absolute;
              top:0;
              right:0;
              padding:4px;
              color:lightgray;
              cursor:pointer;
              transition:all .2s;

              &:hover{
                transform:scale(1.2);
              }
            }
          }
        }
      }
    }
  }
  .resources-picker{

    .selectable-resources{
      //display:flex;
      //flex-flow:column;
      width:100%;

      .resource{
        display:flex;
        flex-flow:row;
        height:100px;
        border-radius:15px;
        margin-bottom:5px;
        overflow:hidden;
        cursor:pointer;

        &.selected{
          border:1px solid #8bc34a;

          .wrapper-info{
            color:#8bc34a;
          }
        }
        &.available{
          border:1px solid lightgray;

          .wrapper-info{
            color:gray;
          }
        }

        .wrapper-info{
          flex:2;
          display:flex;
          align-items:center;
          box-sizing:border-box;
          padding-left:20px;
        }
        .wrapper-thumbnail{
          flex:1;
          background-position:center!important;
          background-size:cover!important;
          background-repeat:no-repeat!important;
        }
      }
    }
  }
  .buckets-picker{

    .selectable-buckets{
      display:flex;
      flex-flow:column;
      width:100%;

      .bucket{
        padding:10px;
        box-shadow:0 0 5px lightgray;
        border:1px solid lightgray;
        margin:5px;
        cursor:pointer;
        color:gray;

        &.selected{
          border:1px solid #8bc34a;
          color:#8bc34a;
        }
        &.available{

          &:hover{
            color:inherit;
          }
        }
      }
    }
  }
}

.top-bar-collapse-button{
  display:flex;
  justify-content:center;

  span{
    cursor:pointer;
    user-select: none;
    transition:all .2s;

    &:hover{
      transform:scale(1.2);
    }
  }
}
