@import '~@/sass/_variables.scss';

.grid-top-bar{
  padding:5px 10px;
  display:flex;
  flex-flow:row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 10px 10px #E0E0E0;

  .grid-action-create{

  }
  .grid-section-search{
    display:flex;
  }
}
.table-wrapper{
  max-height: 80vh;
  overflow-y: auto;
}
table {
  //border: 2px solid #42b983;
  //border-radius: 3px;
  width:-webkit-fill-available;
  margin:10px;
  box-shadow:0 0 10px gray;
  background-color: white;
  table-layout:auto;

  tr{
    cursor:pointer;
    border-bottom:1px dotted gray;


    &:nth-child(odd){
      background-color:$row-odd;
    }
    &.not-approved{
      background-color:#b71c1c91;
    }
    &.selected {

      td{

      }
    }

    th {
      font-weight:normal;
      background-color: #42b983;
      color: rgba(255,255,255,0.66);
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    td {

      img.resource-thumbnail, iframe{
        max-width:300px;
      }
      &.actions{
        display:flex;
        flex-flow:row;

        button.action-edit, button.action-destroy{
          flex:1;
        }
      }
    }

    th, td {
      font-size:.9em;
      //min-width: 120px;
      padding: 10px 20px;
    }

    th.active {
      color: #fff;
    }

    th.active .arrow {
      opacity: 1;
    }

    .arrow {
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 0;
      margin-left: 5px;
      opacity: 0.66;
    }

    .arrow.asc {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid #fff;
    }

    .arrow.dsc {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #fff;
    }
  }
}
