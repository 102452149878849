$graysh-blue:#607D8B;
$red1:#C62828;

.modal-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;

  .modal-container {
    display:flex;
    flex-flow:column;
    position:absolute;
    top:0;
    right:0px;
    width:50vw;
    height:100vh;
    box-sizing:border-box;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;

    .header{
      display:flex;
      flex-flow:row;
      border-bottom:1px solid $graysh-blue;

      .title{
        flex:1;
        margin:10px 0;
      }
    }

    .modal-header{
      display:flex;
      flex:1;
      flex-flow:row;
      align-items:center;
      border-bottom:1px solid $graysh-blue;
      height:50px;


      .title{
        flex:1;

        h4{
          color:$graysh-blue;
          margin:0;
        }
      }
      .action-close{
        i.material-icons{
          cursor:pointer;
          transition:all .2s;
          color:$red1;

          &:hover{
            transform:scale(1.2) rotate(90deg);
          }
        }
      }
    }

    .modal-body {
      display:flex;
      height:90%;
      overflow-y:auto;
      margin: 20px 0;

      div:first-child{
        //width:100%;
      }
    }
  }
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container, .modal-leave-active .modal-container {
  -webkit-transform: scale(1.1) translate(100px);
  transform: scale(1.1) translate(100px);
}
