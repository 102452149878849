.nested-page#Resources{

  .grid-top-bar{

    .filters{
      display:flex;
      flex-flow:row;
      align-items:center;
      width:85vw;

      h5{
        margin:0;
      }

      .filter{
        padding:5px;
        margin:0 5px;

        &.paginator{
          display:flex;
          flex-flow:row;
          justify-content: space-between;
          box-shadow:0 0 10px gray;

          .page{
              margin:5px;
          }
        }

        &.resources_categories{
          width:30%;
        }
      }
    }

  }

  .table-wrapper{
    table{
      thead{
        tr{
          th{

          }
        }
      }
      tbody{
        tr{
          td{

            &.col-1{
              position:relative;
              box-sizing:border-box;
              width:394px;
              height:216px;
              min-height:216px;
              background-size: cover;
              background-position: center;

              .info{
                position:absolute;
                color:white;
                background-color:rgba(0,0,0, 0.5);
                padding:10px;
                top:10px;
                left:10px;
              }
            }

            &.col-2{
              max-width:200px;
              overflow-y:auto;

              .title{
                margin:5px 0;
              }
              .demand{
                margin-bottom:10px;
              }
            }
            &.categories{
              max-width:150px;

              ul{
                list-style-type: none;
                padding:0;

                li.category{
                  box-sizing:border-box;
                  border:1px dashed gray;
                  border-radius:15px;
                  padding:5px;
                  margin:5px 0;
                  text-align:center;
                }
              }
            }
            &.actions{
              overflow:auto;
              max-width:150px;

              button{
                outline:none;
                border:none;
                padding:5px;
                cursor:pointer;
                transition:all .2s;

                &.action-edit{
                  color:#512da8;
                  background-color:#FFC107;
                  border-top-left-radius:15px;
                  border-bottom-left-radius:15px;
                  box-shadow:-5px 0 5px #adadad;

                  transform: rotateY(20deg);
                  perspective: 100px;
                  perspective-origin: right;

                  &:active{
                    transform:rotateY(-20deg);
                  }
                }
                &.action-destroy{
                  color:white;
                  background-color:#f44336;
                  border-top-right-radius:15px;
                  border-bottom-right-radius:15px;
                  box-shadow:5px 0 5px #adadad;

                  transform: rotateY(-20deg);
                  perspective: 100px;
                  perspective-origin: left;

                  &:active{
                    transform:rotateY(20deg);
                  }
                }

              }
            }
          }
        }
      }
    }
  }
}
