
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Tables
$row-active:#f5f5f5;
$row-odd:#ECEFF1;
$machine-online_font-color:#fff59d;
$machine-online:#43a047;
$machine-online_odd-row:#337536;
$machine-offline_font-color:inherit;
$machine-offline:#808080;
$machine-offline_odd-row:#616161;
$machine-missing_font-color:#fff9c4;
$machine-missing:#d32f2f;
$machine-missing_odd-row:#b71c1c;

$low-disk-space:#e64a19;


//##### Marius reforms #####
