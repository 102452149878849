
.nested-page#Machines{

  .grid-top-bar{
    display:flex;
    flex-flow:row;

    .download-client{
      flex:1;

    }
    .grid-section-search{
      flex:1;
    }
  }
}
